import { Button, ButtonGroup, IconButton } from "@chakra-ui/react";

import { DownloadIcon } from "@chakra-ui/icons";
import React from "react";

interface PlayerFooterProps {
  downloadURL?: string;
  playbackRate?: 1.0 | 1.5 | 2.0;
  onPlaybackRateChange: (playbackRate: 1.0 | 1.5 | 2.0) => void;
}

function PlayerFooter({
  downloadURL,
  playbackRate = 1.0,
  onPlaybackRateChange,
}: PlayerFooterProps) {
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "1024px",
          margin: "0 auto",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "32px",
            width: "100%",
          }}
        >
          {/* This is a spacer in order to get center alignment of the playback rate buttons*/}
          <IconButton
            aria-label="Download file"
            size="lg"
            variant="outline"
            icon={<DownloadIcon />}
            css={{ opacity: "0" }}
          />
          <ButtonGroup variant="outline" isAttached size="lg">
            <Button
              variant={playbackRate === 1.0 ? "solid" : "outline"}
              onClick={() => onPlaybackRateChange(1.0)}
            >
              1x
            </Button>
            <Button
              variant={playbackRate === 1.5 ? "solid" : "outline"}
              onClick={() => onPlaybackRateChange(1.5)}
            >
              1.5x
            </Button>
            <Button
              variant={playbackRate === 2.0 ? "solid" : "outline"}
              onClick={() => onPlaybackRateChange(2.0)}
            >
              2x
            </Button>
          </ButtonGroup>

          <a href={downloadURL} download>
            <IconButton
              aria-label="Download file"
              size="lg"
              variant="outline"
              icon={<DownloadIcon />}
              css={{ marginLeft: "16px" }}
            />
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}

export default React.memo(PlayerFooter);
