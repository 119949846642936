import { Divider, IconButton, useColorMode } from "@chakra-ui/react";

import React from "react";
import { SunIcon } from "@chakra-ui/icons";

interface HeaderProps {
  downloadURL?: string;
}

function Header({ downloadURL }: HeaderProps) {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <header style={{ padding: "32px 0" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 32px",
        }}
      >
        {colorMode === "dark" ? (
          <svg
            width="170"
            height="32"
            viewBox="0 0 170 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_405_417)">
              <path
                d="M57.988 19.1223H63.4365C63.6565 20.9114 64.8578 21.8059 66.7699 21.8059C68.4958 21.8059 69.5957 21.1645 69.5957 20.0169C69.5957 18.3966 67.396 18.2278 64.9932 17.8059C61.8459 17.2827 58.4787 16.4388 58.4787 12.2025C58.4787 8.40504 62.0151 6.43036 66.2961 6.43036C71.4232 6.43036 74.1813 8.64133 74.4689 12.2025H69.1558C68.9358 10.5823 67.8529 10.0928 66.2623 10.0928C64.8409 10.0928 63.758 10.616 63.758 11.7468C63.758 13.0126 65.8054 13.1983 68.1067 13.6202C71.2878 14.1434 75.0442 14.9536 75.0442 19.578C75.0442 23.5274 71.5416 25.5696 66.8038 25.5696C61.4906 25.519 58.1741 23.0886 57.988 19.1223Z"
                fill="white"
              />
              <path
                d="M76.9563 6.93669H82.4725V9.11391H82.574C83.8093 7.49365 85.586 6.39661 88.0564 6.39661C92.862 6.39661 95.8062 10.5147 95.8062 15.9325C95.8062 21.7721 92.6589 25.5021 87.921 25.5021C85.586 25.5021 83.9277 24.5907 82.794 23.0717H82.7263V30.8861H76.9563V6.93669ZM89.9685 16.1519C89.9685 13.0464 88.8009 11.0042 86.3305 11.0042C83.8262 11.0042 82.5402 13.2152 82.5402 16.1519C82.5402 19.0717 83.9616 21.0802 86.432 21.0802C88.6317 21.0802 89.9685 19.2236 89.9685 16.1519Z"
                fill="white"
              />
              <path
                d="M97.7859 6.9367H103.302V9.75526H103.404C104.673 7.61181 106.162 6.64978 108.362 6.64978C108.886 6.64978 109.241 6.68354 109.495 6.7848V11.7131H109.36C105.823 11.2236 103.556 12.9451 103.556 16.8607V24.962H97.7859V6.9367V6.9367Z"
                fill="white"
              />
              <path
                d="M111.238 18.7679V6.93671H116.974V17.5696C116.974 19.5443 117.854 20.6245 119.699 20.6245C121.678 20.6245 122.88 19.1392 122.88 16.9958V6.93671H128.65V24.962H123.133V22.7511H123.015C121.712 24.5063 120.122 25.5021 117.6 25.5021C113.438 25.4852 111.238 22.7679 111.238 18.7679Z"
                fill="white"
              />
              <path
                d="M130.629 15.9831C130.629 10.5654 134.386 6.44727 140.105 6.44727C145.029 6.44727 148.176 9.29959 148.701 13.3502H143.151C142.796 11.73 141.814 10.6667 140.173 10.6667C137.702 10.6667 136.45 12.7089 136.45 15.9831C136.45 19.1899 137.685 21.2321 140.173 21.2321C141.983 21.2321 143.066 20.1688 143.32 18.1266H148.836C148.701 22.2785 145.368 25.519 140.241 25.519C134.403 25.519 130.629 21.4008 130.629 15.9831Z"
                fill="white"
              />
              <path
                d="M150.613 15.9156C150.613 10.5317 154.403 6.41351 159.92 6.41351C162.508 6.41351 164.556 7.25739 166.113 8.65824C168.278 10.6329 169.328 13.7553 169.294 17.4177H156.282C156.637 19.8481 157.974 21.3671 160.343 21.3671C161.865 21.3671 162.847 20.692 163.354 19.5781H168.972C168.583 21.1983 167.517 22.7511 165.926 23.8312C164.404 24.8945 162.559 25.4852 160.258 25.4852C154.302 25.4852 150.613 21.3671 150.613 15.9156ZM163.456 14.0084C163.236 11.865 161.899 10.481 160.021 10.481C157.821 10.481 156.688 11.8481 156.298 14.0084H163.456Z"
                fill="white"
              />
              <path
                d="M19.256 0.0432739C13.3506 0.0432739 8.56198 4.81964 8.56198 10.7099C8.56198 10.7775 8.56198 10.845 8.56198 10.9293C3.67184 11.9083 0 16.212 0 21.3766V32.0433H25.669C31.5744 32.0433 36.363 27.2669 36.363 21.3766C36.363 21.3091 36.363 21.2416 36.363 21.1572C41.2532 20.1783 44.925 15.8576 44.925 10.7099V0.0432739H19.256ZM25.669 28.6677H3.38418V21.3766C3.38418 17.8829 5.87156 14.9462 9.15421 14.2542C10.6094 18.4061 14.5858 21.3766 19.2391 21.3766H32.9619C32.9789 25.3935 29.6962 28.6677 25.669 28.6677ZM12.7753 14.0855H25.669C28.4779 14.0855 30.9145 15.672 32.1497 18.0011H19.256C16.4302 18.0011 13.9936 16.4146 12.7753 14.0855ZM41.5239 10.7099C41.5239 14.2036 39.0365 17.1403 35.7539 17.8323C34.2987 13.6804 30.3223 10.7099 25.669 10.7099H11.9462C11.9462 6.69306 15.2288 3.4188 19.256 3.4188H41.5408V10.7099H41.5239Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_405_417">
                <rect width="169.294" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <svg
            width="169"
            height="32"
            viewBox="0 0 169 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M57.5328 19.1328H62.9418C63.1601 20.9134 64.3528 21.7869 66.2341 21.7869C67.9475 21.7869 69.0394 21.1486 69.0394 20.0063C69.0394 18.3937 66.8725 18.2257 64.4872 17.8058C61.3628 17.285 58.0368 16.4451 58.0368 12.2457C58.0368 8.46614 61.5475 6.50079 65.7974 6.50079C70.8872 6.50079 73.6252 8.70131 73.894 12.2457H68.6362C68.4179 10.6331 67.3428 10.1459 65.7638 10.1459C64.3528 10.1459 63.2777 10.6667 63.2777 11.7921C63.2777 13.052 65.3103 13.2199 67.5948 13.6399C70.7528 14.1606 74.4651 14.9669 74.4651 19.5528C74.4651 23.4667 70.988 25.4992 66.2845 25.4992C60.9932 25.4992 57.7008 23.0803 57.5328 19.1328Z"
              fill="#111921"
            />
            <path
              d="M76.3297 7.02152H81.8058V9.18845H81.9066C83.1328 7.57585 84.8966 6.50079 87.3491 6.50079C92.1197 6.50079 95.0257 10.5995 95.0257 15.9916C95.0257 21.8037 91.9013 25.516 87.1979 25.516C84.8798 25.516 83.2336 24.6089 82.1081 23.0971H82.0409V30.8745H76.3297V7.02152V7.02152ZM89.2304 16.1932C89.2304 13.1192 88.0714 11.0866 85.6189 11.0866C83.1328 11.0866 81.873 13.2871 81.873 16.1932C81.873 19.0992 83.284 21.0982 85.7365 21.0982C87.9034 21.0982 89.2304 19.2336 89.2304 16.1932Z"
              fill="#111921"
            />
            <path
              d="M96.9911 7.02152H102.467V9.82677H102.568C103.828 7.69344 105.306 6.75275 107.473 6.75275C107.994 6.75275 108.346 6.78635 108.598 6.88714V11.7921H108.464C104.953 11.305 102.719 13.0184 102.719 16.8987V24.9449H97.0079V7.02152H96.9911Z"
              fill="#111921"
            />
            <path
              d="M110.329 18.7801V7.02152H116.006V17.5874C116.006 19.5528 116.88 20.6278 118.711 20.6278C120.676 20.6278 121.869 19.1496 121.869 17.0163V7.02152H127.58V24.9449H122.104V22.7444H122.003C120.71 24.4913 119.131 25.4656 116.628 25.4656C112.496 25.4656 110.329 22.778 110.329 18.7801Z"
              fill="#111921"
            />
            <path
              d="M129.545 16.0252C129.545 10.6331 133.258 6.53438 138.952 6.53438C143.824 6.53438 146.948 9.37322 147.469 13.3879H141.959C141.606 11.7753 140.632 10.7339 139.019 10.7339C136.567 10.7339 135.341 12.7664 135.341 16.0252C135.341 19.2168 136.567 21.2325 139.019 21.2325C140.817 21.2325 141.892 20.1743 142.144 18.1585H147.62C147.486 22.2908 144.176 25.4992 139.103 25.4992C133.291 25.4992 129.545 21.4005 129.545 16.0252Z"
              fill="#111921"
            />
            <path
              d="M149.367 15.9412C149.367 10.5827 153.113 6.50079 158.589 6.50079C161.142 6.50079 163.192 7.34068 164.72 8.73491C166.854 10.7003 167.912 13.8079 167.878 17.453H154.961C155.313 19.8719 156.64 21.3669 158.992 21.3669C160.504 21.3669 161.478 20.695 161.982 19.5863H167.559C167.173 21.1989 166.114 22.7276 164.535 23.8194C163.024 24.8777 161.209 25.4656 158.925 25.4656C153.012 25.4656 149.367 21.3669 149.367 15.9412ZM162.1 14.0598C161.881 11.9265 160.554 10.5659 158.69 10.5659C156.523 10.5659 155.397 11.9265 155.011 14.0598H162.1Z"
              fill="#111921"
            />
            <path
              d="M0.168091 31.9161V21.3334C0.168091 15.4877 4.9051 10.7507 10.7676 10.7507H25.6001C31.4458 10.7507 36.1996 15.4877 36.1996 21.3334C36.1996 27.1791 31.4626 31.9161 25.6001 31.9161H0.168091Z"
              fill="url(#paint0_linear_405_251)"
            />
            <path
              d="M44.6825 0.168064V10.7507C44.6825 16.5964 39.9455 21.3334 34.083 21.3334H19.2505C13.4048 21.3334 8.651 16.5964 8.651 10.7507C8.651 4.90507 13.388 0.168064 19.2505 0.168064H44.6825Z"
              fill="#CCE5FF"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M36.1975 21.1234C35.5144 21.2611 34.8073 21.3334 34.083 21.3334H19.2505C13.475 21.3334 8.76533 16.7095 8.65308 10.9608C9.33619 10.823 10.0433 10.7507 10.7676 10.7507H25.6001C31.3756 10.7507 36.0853 15.3747 36.1975 21.1234Z"
              fill="#66B2FF"
            />
            <defs>
              <linearGradient
                id="paint0_linear_405_251"
                x1="18.1838"
                y1="10.7507"
                x2="18.1838"
                y2="31.9161"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#007FFF" />
                <stop offset="1" stop-color="#0061FF" />
              </linearGradient>
            </defs>
          </svg>
        )}
        <div>
          <IconButton
            aria-label={
              colorMode === "dark"
                ? "Switch to light mode"
                : "Switch to dark mode"
            }
            icon={<SunIcon />}
            onClick={toggleColorMode}
          />
        </div>
      </div>
      <Divider css={{ marginTop: "32px" }} />
    </header>
  );
}

export default React.memo(Header);
