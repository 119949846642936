import { Box, Heading, Image, useColorMode } from "@chakra-ui/react";

import Header from "./components/Header";
import Player from "./components/Player";
import React from "react";

function App() {
  const { colorMode } = useColorMode();

  const params = new URLSearchParams(window.location.search);

  const url = (params.get("url") || "").trim();

  const isMovieURL = (url: string) => {
    return [".mov", ".mp4"].some((ext) => url.endsWith(ext));
  };

  let isRedirecting = false;
  if (url && !isMovieURL(url) && url.startsWith("http")) {
    window.location.href = url;
    isRedirecting = true;
  }

  // Choose a random number between one and four that's an integer
  const random404 = Math.floor(Math.random() * 4) + 1;

  const src404 = `/images/404/${colorMode}/${random404}.jpg`;

  return (
    <div>
      <Header downloadURL={url} />

      {isRedirecting ? (
        <React.Fragment>
          <div>You are being redirected you to download the file.</div>
          <p>
            <a href={url} download>
              Download Now
            </a>
          </p>
        </React.Fragment>
      ) : url ? (
        <React.Fragment>
          <Player url={url} />
        </React.Fragment>
      ) : (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              maxWidth: "1024px",
              flexShrink: 0,
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Image
              src={src404}
              css={{ marginTop: "32px" }}
              width={512}
              height={512}
            />
            <Heading css={{ marginTop: "24px" }}>Not Found</Heading>
          </div>
        </Box>
      )}
    </div>
  );
}

export default App;
