import React, { useEffect, useState } from "react";

import PlayerFooter from "./PlayerFooter";
import ReactPlayer from "react-player";

const playbackRateStr = window.localStorage.getItem("playbackRate");
const playbackRateInitial = (playbackRateStr ? +playbackRateStr : 1.0) as
  | 1.0
  | 1.5
  | 2.0;

export interface PlayerProps {
  url: string;
}

function Player({ url }: PlayerProps) {
  const [playbackRate, setPlaybackRate] = useState(playbackRateInitial);

  useEffect(() => {
    if (!playbackRate) return;
    window.localStorage.setItem("playbackRate", playbackRate.toString());
  }, [playbackRate]);

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: "1024px",
            flexShrink: 0,
            flexGrow: 1,
            display: "inline-block",
          }}
        >
          <div
            style={{
              // 16:9 aspect ratio: https://css-tricks.com/aspect-ratio-boxes/
              position: "relative",
              paddingTop: "56.25%",
              height: 0,
              borderRadius: "8px",
              backgroundColor: "black",
              overflow: "hidden",
            }}
          >
            <ReactPlayer
              width="100%"
              height="100%"
              url={url}
              style={{
                position: "absolute",
                top: "0",
                left: "0",
              }}
              controls
              playbackRate={playbackRate}
            />
          </div>
        </div>
      </div>
      <PlayerFooter
        downloadURL={url}
        playbackRate={playbackRate}
        onPlaybackRateChange={setPlaybackRate}
      />
    </React.Fragment>
  );
}

export default React.memo(Player);
